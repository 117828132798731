import React from "react"
import Img from "gatsby-image"

const ProductSimilar = ({ name, rating, reviewsAmount, brand, internalLink, gaEventFunction, affiliateLink, image }) => {
    const combinedName = `${brand} ${name}`;
    const ratingSymbol = '⭐️';
    const ratingStars = ratingSymbol.repeat(rating);

    return (
        <>
            <div className="card text-center mb-3" style={{ width: '18rem' }}>
                <h5 className="card-header">{name}</h5>

                <a href={affiliateLink}>
                    <Img className="card-img-bottom" fluid={image} alt={combinedName} />
                </a>

                <div className="card-body">
                    {rating} von 5 {ratingStars}<br /><br />
                    <a href={affiliateLink} onClick={gaEventFunction}><span className="badge badge-primary">{reviewsAmount}</span> Reviews</a>
                </div>

                <div className="list-group list-group-flush">
                    <a type="button" className="btn btn-success" href={affiliateLink} onClick={gaEventFunction}><span role="img" aria-label="Shopping Cart">✔</span> Zum Angebot</a>
                </div>
            </div>
        </>
    )
}

export default ProductSimilar




